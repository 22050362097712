<template>
    <div class="centerWrap newcontent">
        <div class="title">
            <h1 style="text-align: center; font-size: 60px">
                携手发展，共创未来。
            </h1>
        </div>
        <div class="title cooperative"></div>
        <article style="font-size: 24px; color: #003399">招商加盟</article>
        <article>
            北京希思腾科智能科技有限公司作为拥有领先技术的高科技公司，在智慧家庭、智慧酒店、智能建筑等领域深耕，提供对于针对不同场景的智能化解决方案，为客户提供高品质的智能产品和系统解决方案，拥有设备齐全的生产和检测基地，在全国的工程服务和销售网络。
            公司竭诚面向全国诚聘经销商，以互惠双赢为原则，共同搭建营销平台，协同发展，根据不同地区制定合作政策和方案，共同制定市场策略。
        </article>
        <article style="font-size: 24px; color: #003399">加盟支持</article>
        <article>
            北京希思腾科智能科技有限公司在全国按照区域划分，进行区域市场渠道拓展管理，建立省市级产品代理机制，本着互惠互赢的原则制定多元化商务政策共同谋求发展。从产品技术体系建立和培训、项目方案支持到技术工程人员培训，为合作伙伴量身定制提供多层次专业化、标准化的服务和合理的伙伴激励政策。
        </article>
        <article style="font-size: 24px; color: #003399">加盟优势</article>
        <article>
            1、公司以领先技术、创新产品和成熟案例作为市场保证，统一设计和策划系列的国内外策略，与各地经销商协同合作。
            2、开展系列的品牌宣传活动，通过召开技术论坛、参加展会、组织行业沙龙等形式进行品牌宣传。
            3、通过统一的产品展示方案，品牌设计和输出，在产品功能、体验风格、宣传材料等方面进行品牌强化和
            宣传工作，加强客户对产品品牌的认知和接受程度，支持区域销售工作。
            4、根据项目类型和进展阶段，公司提供工程设计和服务指导，并定期培训安装调试人员，确保项目落地实施。
        </article>
        <div><img src="../assets/cooperative02.jpg" /></div>
    </div>
</template>

<script>
export default {
    name: 'Cooperative',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    .cooperative {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../assets/cooperative01.jpg");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: bottom center;
        height: 316px;

        h1 {
            font-family: "MiSans-Medium";
            font-size: 64px;
        }
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}</style>