<template>
    <div class="centerWrap newcontent">
        <div><img src="../assets/honor01.jpg" /></div>
        <div><img src="../assets/honor02.jpg" /></div>
        <div><img src="../assets/honor03.jpg" /></div>
        <div><img src="../assets/honor04.jpg" /></div>
        <div><img src="../assets/honor05.jpg" /></div>
        <div style="display: flex">
            <img style="width: 50%" src="../assets/honor06.jpg" />
            <img style="width: 50%" src="../assets/honor07.jpg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileHonor',
}
</script>

<style lang="less" scoped>
.newcontent {
  font-size: 16px;
  padding: 20px 0 0 0;
  padding: 0 0 20px 0;
  div {
    img {
      width: 100%;
    }
  }
}
</style>