<template>
    <div class="featureMarquee-item" href="">
        <img :src='imgUrl' :style="wd" style="margin-left: 0; margin-top: 0;" />
    </div>
</template>

<script>
export default {
    name: 'PartnerBox',
    props: ['imgUrl', 'wd']
}
</script>

<style>
.featureMarquee-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 320px;
    margin: 20px;
    background-color: #f7f7f7;
}
</style>