<template>
    <div class="pagein">
        <div><img :src= '"../assets/" + $route.query.id + ".jpg"' /></div>
    </div>
</template>

<script>
export default {
    name: 'ProductPanelseries',
    mounted(){
        console.log(this.$route.query);
    }
}
</script>

<style lang="less" scoped>
.pagein {
    padding: 0 20px 20px 20px;

    div {
        img {
            width: 100%;
        }
    }

    article {
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}
</style>