<template>
    <div class="featureMarquee centerWrap">
        <div class="featureMarquee-title" style="padding-bottom: 20px">
            他们都选择了与希思腾科合作
        </div>
        <div class="featureMarquee-content">
            <div class="featureMarquee-list">
                <div class="featureMarquee-list__container">
                    <partner-box :imgUrl='require("@/assets/logo/alibaba.png")' wd="width:140px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/sunac.png")' wd="width:140px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/csgec.png")' wd="width:130px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/yintai.png")' wd="width:80px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/jinmao.png")' wd="width:170px"></partner-box>
                </div>
            </div>
            <div class="featureMarquee-list">
                <div class="featureMarquee-list__container">
                    <partner-box :imgUrl='require("../assets/logo/zonglvquan.png")' wd="width:140px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/beichen.png")' wd="width:140px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/fanhai.png")' wd="width:180px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/ncn.png")' wd="width:180px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/huawei_logo.png")' wd="width:150px"></partner-box>
                </div>
            </div>
            <div class="featureMarquee-list">
                <div class="featureMarquee-list__container">
                    <partner-box :imgUrl='require("../assets/logo/chinaMobile.png")' wd="width:140px"></partner-box>
                    <div class="featureMarquee-item" href="">
                        <div style="
                    margin-left: 0;
                    margin-top: 0;
                    width: 98px;
                    height: 100px;
                    background-repeat: no-repeat;
                    background-attachment: fixed;
                    background-position: center left;
                  " :style="{ backgroundImage: 'url(' + require('@/assets/logo/chinaUnicom.png') + ')' }"></div>
                    </div>
                    <partner-box :imgUrl='require("../assets/logo/cnjj.png")' wd="width:100px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/sanhome.png")' wd="width:120px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/crLand.png")' wd="width:140px"></partner-box>
                </div>
            </div>
            <div class="featureMarquee-list">
                <div class="featureMarquee-list__container">
                    <partner-box :imgUrl='require("../assets/logo/wasu.png")' wd="width:140px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/vanke.svg")' wd="width:140px"></partner-box>
                    <partner-box :imgUrl='require("../assets/logo/minmetals.png")' wd="width:100px"></partner-box>

                    <div class="featureMarquee-item" href="">
                        <!-- <img
                  src="images/sanhome.png"
                  style="margin-left: 0; margin-top: 0; width: 130px"
                /> -->
                    </div>
                    <div class="featureMarquee-item" href="">
                        <!-- <img
                  src="images/wasu.png"
                  style="margin-left: 0; margin-top: 0; width: 160px"
                /> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PartnerBox from '../components/PartnerBox.vue'
export default {
    name: 'PartnerModel',
    components: { PartnerBox }
}
</script>

<style lang="less" scoped>
.featureMarquee {
    padding: 80px 0 80px 0;
    // max-width: 2560px;
    // width: 100%;
    overflow: hidden;
    color: #111;
    font-size: 16px;

    .featureMarquee-title {
        font-family: "MiSans-Regular", sans-serif !important;
        text-align: center;
        max-width: 1200px;
        margin: auto;
        width: calc(100% - 32px);
        padding: 0 16px;
        font-size: 36px;
        color: #111;
        line-height: 40px;
        letter-spacing: 1.5px;
    }

    // .tab {
    //     margin: 40px 0 24px 0;

    //     .tab-list {
    //         display: inline-table !important;
    //         // position: relative;
    //         margin: 0 auto;
    //         min-width: 100%;
    //         white-space: nowrap;
    //         scrollbar-width: none;
    //         text-align: center !important;

    //         .tab-item {
    //             display: inline-block;
    //             width: auto !important;
    //             margin: 0 !important;

    //             .tab-item-title {
    //                 position: relative;
    //                 padding-bottom: 2px;
    //                 font-size: 16px;
    //                 font-family: MiSans-Medium;
    //                 color: #000;
    //                 line-height: 24px;
    //                 margin: 0 20px;
    //                 transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    //             }

    //             .tab-item-line {
    //                 width: 100%;
    //                 height: 2px;
    //                 position: absolute;
    //                 background-color: #000;
    //                 bottom: 0;
    //                 left: 50%;
    //                 transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    //                     -webkit-transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    //                 transform: translateX(-50%) scaleX(0);
    //             }
    //         }

    //         .active {
    //             .tab-item-line {
    //                 transform: translateX(-50%) scaleX(1);
    //             }
    //         }
    //     }
    // }

    // .swiper {
    //     width: 98%;
    //     height: 650px;

    //     .swiper-wrapper {
    //         width: 1200px;
    //         height: 650px;

    //         .swiper-slide {
    //             position: relative;
    //             text-align: center;
    //             font-size: 18px;
    //             background: #fff;
    //             display: -webkit-box;
    //             display: -ms-flexbox;
    //             display: -webkit-flex;
    //             display: flex;
    //             -webkit-box-pack: center;
    //             -ms-flex-pack: center;
    //             -webkit-justify-content: center;
    //             justify-content: center;
    //             -webkit-box-align: center;
    //             -ms-flex-align: center;
    //             -webkit-align-items: center;
    //             align-items: center;
    //             height: 650px;

    //             img {
    //                 display: block;
    //                 width: 100%;
    //                 height: 650px;
    //                 object-fit: cover;
    //                 border-radius: 14px;
    //             }

    //             .innertext {
    //                 position: absolute;
    //                 visibility: visible;
    //                 bottom: 16px;
    //                 width: 1168px;
    //                 margin: 0 auto;
    //                 overflow: hidden;
    //                 opacity: 0;
    //                 position: absolute;
    //                 bottom: 16px;
    //                 transition: all 0.7s;
    //                 left: 50%;
    //                 transform: translateX(-50%);
    //                 background: rgba(0, 0, 0, 0);

    //                 .innertext-box {
    //                     background: linear-gradient(180deg,
    //                             rgba(0, 0, 0, 0) 0,
    //                             rgba(0, 0, 0, 0.5) 100%);
    //                     border-radius: 0 0 8px 8px;
    //                     padding: 34px 0 24px 0;

    //                     .text-wrap {
    //                         display: flex;
    //                         flex-direction: column;
    //                         align-items: center;
    //                         justify-content: flex-start;
    //                         margin: 0 auto;

    //                         .headline {
    //                             width: 1120px;
    //                             padding-bottom: 8px;
    //                             font-size: 32px;
    //                             line-height: 38px;
    //                             text-align: center;
    //                             white-space: normal;
    //                             word-break: break-word;
    //                             color: #fff;
    //                         }

    //                         .desc {
    //                             width: 1120px;
    //                             padding-bottom: 16px;
    //                             font-size: 14px;
    //                             line-height: 20px;
    //                             text-align: center;
    //                             word-wrap: break-word;
    //                             white-space: normal;
    //                             color: #fff;
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         &:hover {
    //             .innertext {
    //                 opacity: 1;
    //             }
    //         }
    //     }
    // }

    .featureMarquee-content {
        overflow: hidden;

        .featureMarquee-list {
            will-change: transform;
            transform: rotateX("1000px");

            .featureMarquee-list__container {
                display: flex;
                justify-content: space-between;

                .featureMarquee-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 110px;
                    width: 320px;
                    margin: 20px;
                    background-color: #f7f7f7;
                }
            }
        }
    }
}
</style>