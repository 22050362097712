<template>
    <div class="bottom">
        <div class="centerWrap sitemap">
            <ul class="links">
                <li>
                    <ul>
                        <li><router-link to="/hotel/index">解决方案</router-link></li>
                        <li><router-link to="/hotel/index">智慧酒店</router-link></li>
                        <li><router-link to="/hotel/family">智慧家庭</router-link></li>
                        <li><router-link to="/hotel/olds">智慧养老</router-link></li>
                        <li><router-link to="/hotel/bank">智慧银行</router-link></li>
                        <li><router-link to="/hotel/classroom">智慧教室</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li><router-link to="/profile/index">关于我们</router-link></li>
                        <li><router-link to="/profile/index">公司简介</router-link></li>
                        <li><router-link to="/profile/honor">荣誉资质</router-link></li>
                        <li><router-link to="/profile/recruit">人才招聘</router-link></li>
                        <li><router-link to="/profile/contactus">联系我们</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li><router-link to="/cooperative">招商加盟</router-link></li>
                        <li><router-link to="/cooperative">合作加盟</router-link></li>
                        <li><router-link to="/cooperative">加盟优势</router-link></li>
                        <li><router-link to="/cooperative">加盟支持</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li><router-link to="/product/index">产品中心</router-link></li>
                    </ul>
                </li>
                <!-- <li>
              <ul>
                <li><a href="">新闻中心</a></li>
                <li><a href="">公司动态</a></li>
                <li><a href="">经销商快报</a></li>
                <li><a href="">行业动态</a></li>
              </ul>
            </li> -->
                <!-- <li>
              <ul>
                <li><a href="">技术支持</a></li>
                <li><a href="">产品说明</a></li>
                <li><a href="">培训</a></li>
                <li><a href="">资料下载</a></li>
              </ul>
            </li> -->
            </ul>
            <div class="lcontact">
                <img src="http://www.systemteq.net/theme/default1/images/ewm.jpg" width="100" height="100" />
                <span>微信二维码</span>
            </div>
            <div class="rcontact">
                <div class="tel">400-716-5828</div>
                <div class="fuwu">（服务时间：周一至周五 09：00-18：00）</div>
                <div class="dianji">
                    <a href="http://p.qiao.baidu.com/cps/chat?siteId=11117609&userId=24420381">在线客服</a>
                    <div style="width: 2px;"></div>
                    <a href="http://p.qiao.baidu.com/cps/chat?siteId=11117609&userId=24420381">加盟咨询</a>
                </div>
            </div>
            <div class="copyright">
                COPYRIGHT © 2017 SYSTEMTEQ.COM.CN 北京希思腾科 版权所有
                <a href="https://beian.miit.gov.cn/">京ICP备16030850号-2</a>
                Tel:400-716-5828
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bottom'
}
</script>

<style lang="less" scoped>
.bottom {
    border-top: 1px solid #eee;
    background: #f9f9f9;

    .sitemap {
        display: grid;
        grid-template-columns: 800px 1fr 1fr;
        grid-template-rows: minmax(160px, auto) 47px;
        grid-column-gap: 0;
        grid-template-areas:
            "links lcontact rcontact"
            "bnav bnav bnav";
        color: #111;
        font-size: 14px;
        padding: 0 0 10px 0;

        .links {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            justify-items: center;
            margin: 16px 0 16px 0;
            width: 800px;

            ul {
                li {
                    padding: 6px 0 6px 0;

                    &:first-child {
                        font-size: 18px;
                        font-weight: 600;
                        color: #111;
                    }
                }
            }
        }

        a {
            color: rgba(17, 17, 17, 0.7);
        }

        .lcontact {
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            align-content: center;
            justify-items: center;
            align-items: center;
            padding: 0 0 40px 0;

            span {
                margin: 0px auto;
                width: 98px;
                line-height: 28px;
                color: #fff;
                font-size: 14px;
                border: 1px solid #fff;
                text-align: center;
            }
        }

        .rcontact {
            padding: 36px 0 0 0;
            width: 260px;
            text-align: right;

            .tel {
                color: rgba(1, 1, 1, 0.8);
                font-family: Impact;
                font-size: 42px;
                line-height: 34px;
            }

            .fuwu {
                font-size: 13px;
                line-height: 45px;
            }

            .dianji {
                display: flex;
                flex-direction: row;
                justify-content: end;
                // height: 20px;
                // line-height: 20px;
            }

            a {
                display: inline-block;
                line-height: 20px;
                background: #053a75;
                font-size: 16px;
                color: #fff;
                padding: 4px 8px;
            }
        }
    }

    .copyright {
        margin: 0 auto;
        width: 1200px;
        line-height: 45px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        text-align: center;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;

        a {
            color: rgba(0, 0, 0, 0.7);
        }
    }
}</style>