<template>
  <div id="root">
    <Top></Top>
    <router-view></router-view>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from './components/Top';
import Bottom from './components/Bottom';
export default {
  name: 'App',
  components: { Top, Bottom }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "MiSans-Normal", sans-serif;
}

body {
  -webkit-font-smoothong: antialiased;
  color: #555;
  background-color: #fff;
}

body,
button,
input,
textarea {
  resize: none;
  font-size: 12px;
  line-height: 1.531;
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
}

p,
ul,
ol,
dl,
dt,
dd,
form,
blockquote {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #333333;
}

a:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

em,
b,
i {
  font-style: normal;
  font-weight: normal;
}

img {
  vertical-align: middle;
  border: 0;
}

button {
  cursor: pointer;
  background: transparent;
  text-align: inherit;
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  font: inherit;
  width: auto;
}

label {
  cursor: pointer;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clearfix {
  overflow: hidden;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}

.tac {
  text-align: center;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.fd110:hover {
  transform: scale(1.1);
}

.centerWrap {
  width: 1200px;
  margin: 0 auto;
}

.frow {
  display: flex;
  flex-direction: row;
}

.fcolumn {
  display: flex;
  flex-direction: column;
}
</style>
