<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>希思腾科智慧银行</h1>
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">简化</h1>
                <h1 style="display: inline-block">流程手续，</h1>
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">合理</h1>
                <h1 style="display: inline-block">利用资源</h1>
            </div>
            <article>
                智慧银行提供更加智能化的优质服务简化流程手续，减少多余人力，合理利用资源。还可以营造良好的运营环境，同时保障银行内外部的安全，为银行正常运营保驾护航。<br><br>智慧银行配置自助及智能化设备，有效地疏解人流压力，多渠道完成银行各项业务。
            </article>
            <div><img src="../assets/zhyh.jpeg" /></div>
        </div>
        <section class="section_cona">
            <div class="centerWrap">
                <div class="title">
                    以数据驱动节能，降低运营成本，助力企业数字化升级
                </div>
                <div class="current" style="grid-template-columns: repeat(4, calc((100% - 64px) / 4))">
                    <div class="cardbox">
                        <div class="card-title">01 简化控制管理</div>
                        <div class="card-desc">
                            将灯光效果与设备电源控制完美融合，设计适合日常操作的复数场景，一键直达，快捷方便，将更多的人力精力投入到接待服务中。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">02 强化平台能力</div>
                        <div class="card-desc">
                            建设综合服务场景，升级开放银行平台，扩展开放服务生态，打造”民生云+”开放银行品牌。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">03 建设智慧银行</div>
                        <div class="card-desc">
                            提升移动金融综合服务能力，加速网点智能化转型，提升风险合规管控能力，保障业务合规稳健发展。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">04 构建生态体系</div>
                        <div class="card-desc">
                            打造场景定制化服务，实现智慧共享和精准对接，不断提升客户体验为客户创造价值。
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HotelBank',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}

.section_cona {
    padding: 70px 0 20px 0;
    background: #f5f7fa;

    .title {
        margin: 0 0 20px 0;
        font-family: "MiSans-Regular";
        font-size: 48px;
        line-height: 86px;
        color: #000;
    }

    .current {
        display: grid;
        grid-template-columns: repeat(6, calc((100% - 64px) / 6));
        grid-template-rows: 60px, 1fr;
        justify-content: space-between;

        .cardbox {
            margin: 0 0 16px 0;
            padding: 28px 20px 28px 20px;
            border-radius: 20px;
            background: #ffffff;

            .card-title {
                font-size: 24px;
                color: #000000;
                line-height: 32px;
                margin-bottom: 16px;
                font-family: "MiSans-Medium";
            }

            .card-desc {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}
</style>