<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>我们是谁？</h1>
            </div>
            <div><img src="../assets/profile.jpg" /></div>
            <article>
                北京希思腾科智能科技有限公司是英国 Systemteq 公司在中国大陆唯一产品代理商和战略合作伙伴；专注于智慧酒店、智慧家庭、智慧建筑等领域的高科技企业，以 AloT
                技术赋能智慧空间，通过智能化设备及数字化管理平台，对建筑空间数字化升级，实现“人-机-物-场”协同“感-联-知-用”融合的多场景全流程技术应用。<br><br>母公司辽宁希思腾科信息技术有限公司拥有 3000
                平方米的产业化基地，具有较强的科研能力(团队中博士、硕士及高级技术职称人员占比36%)，在感知互联、人机交互、智能控制等技术领域优势明显；具有核心技术的全部自主知识产权。<br><br>Systemteq
                品牌入围多家国内外知名酒店管理集团，在全国五大区域建立了销售及服务中心，拥有一支专业化的工程设计团队，形成了从产品设计、生产，到设备调试、服务的标准化体系。
            </article>
        </div>
        <!-- <section class="section_cona" style="padding-bottom: 50px">
            <div class="centerWrap">
                <div class="title">全场景、全流程覆盖，打造一站式沉浸体验</div>
                <div class="current" id="cardbox" style="grid-template-columns: repeat(3, calc((100% - 64px) / 3))">
                    <div v-for="n in cardList" :key="n.id" class="cardbox">
                        <div class="card-title">{{ n.title }}</div>
                        <div class="card-desc">{{ n.desc }}</div>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- 发展历程 -->
        <div class="product-cmp">
            <div class="centerWrap">
                <div class="title">发展历程</div>
                <div class="timeline">
                    <div class="timeline-left">
                        <div class="item">
                            <div class="item-title">2022</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        中标南昌力高皇冠假日酒店，昆明恒隆广场君悦酒店，资中温德姆酒店，重庆龙湖时光天际酒店，营口金泰珑悦海景大酒店等酒店项目
                                    </li>
                                    <li>与中国联合网络通信有限公司北京市分公司签约</li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2020</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        中标苏州太湖阿丽拉酒店，济南高新凯宾斯基酒店，北京饭店（诺金），辽阳丽亭酒店，通化希岸酒店，杭州西湖国宾馆，南京菲住布渴酒店
                                        中标浙江嘉兴奢曼酒店，青岛融海耀州酒店（青岛国际院士酒店），咸宁不住不行智慧酒店，重庆零里酒店，延吉和龙市军舰花酒店等
                                        中标南昌苏宁檀悦楼盘项目，长沙印湘江公寓，南宁启迪东盟科技城楼盘，义乌运长集团公寓及鞍山银行等项目
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2018</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        SYSTEMTEQ产品中标杭州阿里未来酒店、北京前门文华东方酒店
                                    </li>
                                    <li>智能家居EL分布式系统曲面智能触摸面板面市</li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2016</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        北京希思腾科智能科技有限公司迁至北京市朝阳区东十里堡1号楼905
                                    </li>
                                    <li>
                                        SYSTEMTEQ产品中标上海北外滩白玉兰广场W酒店、苏州中心W酒店、苏州湾艾美酒店、深圳机场凯悦酒店及长沙梅溪湖豪华精选酒店
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2014</div>
                            <div class="item-description">
                                <ul>
                                    <li>SYSTEMTEQ产品中标成都棕榈泉费尔蒙酒店</li>
                                    <li>SYSTEMTEQ产品中标南京圣和府邸豪华精选酒店</li>
                                    <li>
                                        SYSTEMTEQ产品中标大中华区第一家源宿酒店，即苏州科技城源宿酒店
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2012</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        SYSTEMTEQ产品正式进入西南市场，同年中标重庆北碚悦榕庄酒店
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2008</div>
                            <div class="item-description">
                                <ul>
                                    <li>SYSYTEMTEQ产品全线升级至TCP/IP联网</li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2006</div>
                            <div class="item-description">
                                <ul>
                                    <li>SYSTEMTEQ产品中标北京金融街丽思卡尔顿酒店</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="timeline-right">
                        <div class="item">
                            <div class="item-title">2021</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        中标南昌美高梅酒店，西昌豪生酒店群，青岛藏马山融创安麓酒店，贵州朴语山居野奢度假酒店，通化万峰五洲皇冠酒店等项目
                                    </li>
                                    <li>中标吉林欧亚御龙湾楼盘，义乌运长集团公寓等楼盘项目</li>
                                    <li>
                                        中标西南交大3号教学楼智慧教室，成都职业技术学院等智慧教室项目
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2019</div>
                            <div class="item-description">
                                <ul>
                                    <li>与磐石科技签约</li>
                                    <li>荣获明星企业</li>
                                    <li>开发产品新领域，房车系列</li>
                                    <li>
                                        中标杭州阿里未来人才公寓（杭州亲橙客栈），青岛国信海天大酒店，长春万达中心这有山居酒店，石头记酒店等
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2017</div>
                            <div class="item-description">
                                <ul>
                                    <li>获得国家发改委新兴产业三年行动计划项目支持</li>
                                    <li>鞍山产业化基地和产品演示基地建成运营</li>
                                    <li>
                                        SYSTEMTEQ产品中标镇江苏宁凯悦酒店、徐州苏宁凯悦酒店、长沙湘江豪生酒店
                                    </li>
                                    <li>智能家居系统Ecogearing升级系列化产品</li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2015</div>
                            <div class="item-description">
                                <ul>
                                    <li>SYSYTEMTEQ产品全线升级至全模块化</li>
                                    <li>
                                        SYSTEMTEQ产品中标杭州G20峰会会场酒店，即杭州泛海钓鱼台酒店及杭州国宾馆项目
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2013</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        北京希思腾科科贸有限公司正式更名为北京希思腾科智能科技有限公司
                                    </li>
                                    <li>SYSTEMTEQ产品中标重庆解放碑威斯汀酒店</li>
                                    <li>
                                        SYSTEMTEQ产品中标北京APEC会议主会场酒店，即北京雁栖湖国际会都酒店及北京日出东方凯宾斯基酒店
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2009</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        SYSTEMTEQ产品正式进入海南市场，同年中标三亚海韵假日酒店
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2007</div>
                            <div class="item-description">
                                <ul>
                                    <li>SYSTEMTEQ产品中标北京银泰中心柏悦酒店</li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-title">2005</div>
                            <div class="item-description">
                                <ul>
                                    <li>
                                        北京希思腾科科贸有限公司成立，成为英国SYSTEMTEQ的中国大陆唯一经销商
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 合作伙伴 -->
        <partner-model></partner-model>
    </div>
</template>

<script>
import PartnerModel from '../components/PartnerModel.vue'
export default {
    name: 'ProfileIndex',
    components: { PartnerModel }
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    // .cooperative {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-image: url("../images/cooperative01.jpg");
    //     background-size: 100% auto;
    //     background-repeat: no-repeat;
    //     background-position: bottom center;
    //     height: 316px;

    //     h1 {
    //         font-family: "MiSans-Medium";
    //         font-size: 64px;
    //     }
    // }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}

.product-cmp {
    .title {
        margin: 60px 0 20px 0;
        font-family: "MiSans-Regular";
        font-size: 48px;
        line-height: 86px;
        color: #000;
    }

    .timeline {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .timeline-left {
            // height: 200px;
            padding: 80px 0 0;
            box-sizing: border-box;
            border-right: 1px solid #2d3132;
        }

        .item {
            transition: all 1s;
            opacity: 1 !important;
            transform: translateY(0) !important;
            position: relative;
            width: 100%;
            box-sizing: border-box;
            padding: 0 10% 80px;

            .item-title {
                width: 104.2%;
                overflow: hidden;
                white-space: normal;
                color: #111;
                font-size: 24px;
                padding: 12px 0;
                font-family: MiSans-Medium;
            }

            .item-description {
                width: 104.2%;
                margin: 0;
                padding: 0 0 10px;
                word-wrap: break-word;
                line-height: 28px;

                li {
                    padding: 0;
                    margin: 0;
                    line-height: 28px;
                    font-size: 18px;
                    font-family: MiSans-Medium;
                }
            }
        }
    }

    .timeline-right {
        padding: 160px 0 0;
        min-height: 150px;
        box-sizing: border-box;
    }
}
</style>