<template>
    <div class="centerWrap newcontent">
        <div class="title">
            <h1>人才招聘</h1>
        </div>
        <div><img src="../assets/recruit.png" /></div>
        <article>
            1、驻外营销业务员: 5人
            任职资格：性别不限，年龄25-35岁，大专以上学历、可驻外办公，体育专业，有销售工作经验或从事过房地产行业营销工作者优先。基础工资+绩效奖励，基础工资待遇：3000-5000元/月,根据能力定级；工作地点：全国。
            2、工程技术人员:5人
            任职资格：学历不限，具有1年以上电器安装工程施工及管理经验者优先；可长期驻外。基础工资待遇：3000-5000元/月，基础工资+绩效奖励，根据能力定级；工作地点：全国。
            3、软件开发人员：2人
            任职资格：性别不限，本科及以上学历，计算机或相关专业毕业。一年以上C#..NET开发经验。有大型数据库应用开发经验，MySQL优先。熟悉C/S、B/S体系结构，熟悉WPF开发。有至少参与过一个项目的（真实客户，非论文型）开发经验，对类似架构的软件开发水平达到“熟练”以上程度者优先。工资待遇面议；工作地点：北京。
            4、软件工程师：2人
            任职资格：性别不限，本科及以上学历，计算机或相关专业毕业。有扎实的C语言基础，熟悉C/C++语言编程；
            有大型数据库应用开发经验，如MySQL； 熟悉IP/TCP、HTTP等应用协议；
            熟练使用基本数据结构及其算法(链表、堆栈、队列、树)；
            1年以上linux实际开发经验，熟悉完成的开发流程；有云服务应用开发者优先。有较强的逻辑思维能力,擅于学习专研，具有分析问题、解决问题的能力，具备较强的沟通交流能力者优先。工资待遇面议；工作地点：北京。
            5、IOS开发工程师1人
            任职资格：基于IOS平台进行手机APP、PAD开发；后期APP与PAD端程序维护；负责项目框架搭建、关键技术选型、技术难题公关等工作；
            进行代码开发和单元测试等工作；
            开发过程中定期对工作质量、进度进行评估，保证开发周期可控；
            参与移动规范制订、技术文档编写。薪资待遇:面议；工作地点：北京。
            6、售前服务工程师3人
            任职资格：专科及以上学历，信息工程、电子、自动化、电气、仪器仪表等相关专业。有电力电子相关设计工作经验或建筑工程管理相关工作经验者优先，性格开朗、与人和善、有进取心，能恰当的处理与客户之间的关系。工资待遇：3000-5000元/月，根据能力定级；工作地点：长沙（可短期出差）。
            7、市场公关经理1人
            任职要求：女25岁以上。1、具备独立的行业拓展、市场推广和公关能力，有较好的人脉和协调公关能力；2、具有较强的客户关系建立与维护能力，善于挖掘和满足客户需求；3、外事工作能力强，善于处理复杂的社会关系；4、良好的沟通、协调、交流、组织实施能力，较强的文字组织能力，较好的亲和力；5、能适应短期出差6、较强的团队合作意识和抗压能力；7、大学本科以上学历，市场营销、企业管理和专业；8、有较强的市场感知能力，敏锐地把握市场动态、市场方向的能力；9、5年以上营销或运营相关工作经验，3年以上部门和市场、销售管理经验；10、具有敏感的商业和市场意识，分析问题及解决问题能力强，具有优秀的资源整合能力和业务推进能力；11、熟悉商业地产开发（酒店综合体、别墅、住宅）、了解房地产相关知识，有地产开发、工程建材、酒店开发、等经验者优先。
            工作内容：1、参加各类行业集会，树立公司形象，搜集信息，目标客户公共关系拓展，配合营销部门完成任务，制定产品销售策略；2、
            根据公司的市场推广策略，制定推广计划并推进实施，为公司的产品和服务寻找新的市场（市场布局和推广）；3、
            落实公司网络推广工作，对接线上资源，配合网上招商活动，撰写市场稿件（新闻稿、评论稿、专访稿等）、策划方案、报告；负责公司公众号的日常维护、发布推广等工作；4、
            定期进行市场调查，组织搜集营销信息，分析市场趋势，针对推广效果跟踪与评估，并给出改善方案；5、及时获取市场的项目信息，调动资源开发客户，并协调公司内部的设计、销售人员深入接触客户，提供具有针对性和竞争力的方案，积极运作，达成销售目标；6、规划和管理市场活动内容及预算。
            公司地址：北京市朝阳区东十里堡1号楼未来时大厦905室，国内多省会城市设立办事处。
            联系人： 王女士 咨询电话：400-716-5828 邮箱：wxh@ systemteq.com.cn
        </article>
    </div>
</template>

<script>
export default {
    name: 'ProfileRecruit',
}
</script>

<style lang="less" scoped>
.newcontent {
  font-size: 16px;
  padding: 20px 0 0 0;
  padding: 0 0 20px 0;
  div {
    img {
      width: 100%;
    }
  }
  .title {
    margin: 60px 0 32px 0;
  }
  h1 {
    font-family: "MiSans-Regular";
    font-size: 56px;
    line-height: 86px;
    color: #000;
  }
  article {
    margin: 0 0 29px 0;
    padding: 20px 0 0 0;
    font-size: 20px;
  }
}
</style>