<template>
    <div>
        <div class="banner" href="" target="_blank">
            <div class="slogn">
                AI x IoT 全场景智联<br />软硬件一体化解决方案提供商
            </div>
        </div>
        <article class="main"></article>
        <section class="featureMarquee section1 centerWrap">
            <div class="featureMarquee-title">领先的行业解决方案</div>
            <div thumbsSlider="" class="tab mySwiperTab">
                <ul class="tab-list swiper-wrapper">
                    <li class="swiper-slide tab-item active">
                        <button class="tab-item-title">
                            智慧酒店<span class="tab-item-line"></span>
                        </button>
                    </li>
                    <li class="swiper-slide tab-item">
                        <button class="tab-item-title">
                            智慧家庭<span class="tab-item-line"></span>
                        </button>
                    </li>
                    <li class="swiper-slide tab-item">
                        <button class="tab-item-title">
                            智慧养老<span class="tab-item-line"></span>
                        </button>
                    </li>
                    <li class="swiper-slide tab-item">
                        <button class="tab-item-title">
                            智慧银行<span class="tab-item-line"></span>
                        </button>
                    </li>
                    <li class="swiper-slide tab-item">
                        <button class="tab-item-title">
                            智慧教室<span class="tab-item-line"></span>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                    <router-link class="swiper-slide" to="/hotel/index">
                        <img src="../assets/zhjd.png" alt="" />
                        <div class="innertext">
                            <div class="innertext-box">
                                <div class="text-wrap">
                                    <h3 class="headline">
                                        实现酒店 食、住、行、游、购、娱 智慧化管理
                                    </h3>
                                    <p class="desc">全场景、全流程覆盖，打造一站式沉浸体验</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <router-link class="swiper-slide" to="/hotel/family">
                        <img src="../assets/1.jpg" alt="" />
                        <div class="innertext">
                            <div class="innertext-box">
                                <div class="text-wrap">
                                    <h3 class="headline">
                                        多种方式实现场景联动， 一键操控家庭设施
                                    </h3>
                                    <p class="desc">简单｜舒适｜安全</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <router-link class="swiper-slide" to="/hotel/olds">
                        <img src="../assets/zhyl.jpeg" alt="" />
                        <div class="innertext">
                            <div class="innertext-box">
                                <div class="text-wrap">
                                    <h3 class="headline">
                                        实时、快捷、高效、低成本、智能化 养老服务
                                    </h3>
                                    <p class="desc">实时｜快捷｜安全</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <router-link class="swiper-slide" to="/hotel/bank">
                        <img src="../assets/zhyh.jpeg" alt="" />
                        <div class="innertext">
                            <div class="innertext-box">
                                <div class="text-wrap">
                                    <h3 class="headline">简化流程手续， 合理利用资源</h3>
                                    <p class="desc">节能｜减人｜增效</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <router-link class="swiper-slide" to="/hotel/classroom">
                        <img src="../assets/zhjs.jpeg" alt="" />
                        <div class="innertext">
                            <div class="innertext-box">
                                <div class="text-wrap">
                                    <h3 class="headline">促进信息技术与教育教学融合</h3>
                                    <p class="desc">智慧｜数据｜网络</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </section>

        <!-- 合作伙伴 -->
        <partner-model></partner-model>
    </div>
</template>

<script>
import Swiper from '@/script/swiper-bundle.min.js';
import initialSlide from '@/script/swiper-bundle.min.js';
import PartnerModel from '../components/PartnerModel.vue'
export default {
    name: 'Index',
    components: { PartnerModel },
    mounted() {
        const active = document.querySelectorAll(".tab-list li");
        const act = document.querySelector(".tab-list");
        act.addEventListener("click", function (e) {
            if (e.target.tagName === "BUTTON") {
                document.querySelector(".tab-list .active").classList.remove("active");
                e.target.classList.add("active");
            }
        });

        var swiperTab = new Swiper(".mySwiperTab", {
            spaceBetween: 10,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
        });
        var swiper = new Swiper(".mySwiper", {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                slideChange: function () {
                    console.log(this.activeIndex);
                    if (this.activeIndex == 0) {
                        document
                            .querySelector(".tab-list .active")
                            .classList.remove("active");
                        active[0].classList.add("active");
                    }
                    if (this.activeIndex == 1) {
                        document
                            .querySelector(".tab-list .active")
                            .classList.remove("active");
                        active[1].classList.add("active");
                    }
                    if (this.activeIndex == 2) {
                        document
                            .querySelector(".tab-list .active")
                            .classList.remove("active");
                        active[2].classList.add("active");
                    }
                    if (this.activeIndex == 3) {
                        document
                            .querySelector(".tab-list .active")
                            .classList.remove("active");
                        active[3].classList.add("active");
                    }
                    if (this.activeIndex == 4) {
                        document
                            .querySelector(".tab-list .active")
                            .classList.remove("active");
                        active[4].classList.add("active");
                    }
                },
            },
            speed: 400,
            thumbs: {
                swiper: swiperTab,
            },
        });

        const buttonNext = document.querySelector(".swiper-button-next");
        buttonNext.addEventListener("click", function () {
            console.log(initialSlide);
        });
    }
}
</script>

<style lang="less" scoped>
@import '../style/swiper-bundle.min.css';

.banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 740px;
    min-width: 1152px;
    background-image: url(../assets/1.jpg);
    background-repeat: no-repeat;
    background-position: bottom center;

    .slogn {
        text-align: center;
        margin: 100px 0 0 0;
        color: #fff;
        font-size: 50px;
        letter-spacing: 1px;
    }
}

.section1 {
    padding: 80px 0 0 0 !important;
}

.featureMarquee {
    padding: 80px 0 80px 0;
    // max-width: 2560px;
    // width: 100%;
    overflow: hidden;
    color: #111;
    font-size: 16px;

    .featureMarquee-title {
        font-family: "MiSans-Regular", sans-serif !important;
        text-align: center;
        max-width: 1200px;
        margin: auto;
        width: calc(100% - 32px);
        padding: 0 16px;
        font-size: 36px;
        color: #111;
        line-height: 40px;
        letter-spacing: 1.5px;
    }

    .tab {
        margin: 40px 0 24px 0;

        .tab-list {
            display: inline-table !important;
            // position: relative;
            margin: 0 auto;
            min-width: 100%;
            white-space: nowrap;
            scrollbar-width: none;
            text-align: center !important;

            .tab-item {
                display: inline-block;
                width: auto !important;
                margin: 0 !important;

                .tab-item-title {
                    position: relative;
                    padding-bottom: 2px;
                    font-size: 16px;
                    font-family: MiSans-Medium;
                    color: #000;
                    line-height: 24px;
                    margin: 0 20px;
                    transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
                }

                .tab-item-line {
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    background-color: #000;
                    bottom: 0;
                    left: 50%;
                    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                        -webkit-transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
                    transform: translateX(-50%) scaleX(0);
                }
            }

            .active {
                .tab-item-line {
                    transform: translateX(-50%) scaleX(1);
                }
            }
        }
    }

    .swiper {
        width: 98%;
        height: 650px;

        .swiper-wrapper {
            width: 1200px;
            height: 650px;

            .swiper-slide {
                position: relative;
                text-align: center;
                font-size: 18px;
                background: #fff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                height: 650px;

                img {
                    display: block;
                    width: 100%;
                    height: 650px;
                    object-fit: cover;
                    border-radius: 14px;
                }

                .innertext {
                    position: absolute;
                    visibility: visible;
                    bottom: 16px;
                    width: 1168px;
                    margin: 0 auto;
                    overflow: hidden;
                    opacity: 0;
                    position: absolute;
                    bottom: 16px;
                    transition: all 0.7s;
                    left: 50%;
                    transform: translateX(-50%);
                    background: rgba(0, 0, 0, 0);

                    .innertext-box {
                        background: linear-gradient(180deg,
                                rgba(0, 0, 0, 0) 0,
                                rgba(0, 0, 0, 0.5) 100%);
                        border-radius: 0 0 8px 8px;
                        padding: 34px 0 24px 0;

                        .text-wrap {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            margin: 0 auto;

                            .headline {
                                width: 1120px;
                                padding-bottom: 8px;
                                font-size: 32px;
                                line-height: 38px;
                                text-align: center;
                                white-space: normal;
                                word-break: break-word;
                                color: #fff;
                            }

                            .desc {
                                width: 1120px;
                                padding-bottom: 16px;
                                font-size: 14px;
                                line-height: 20px;
                                text-align: center;
                                word-wrap: break-word;
                                white-space: normal;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            &:hover {
                .innertext {
                    opacity: 1;
                }
            }
        }
    }

    // .featureMarquee-content {
    //     overflow: hidden;

    //     .featureMarquee-list {
    //         will-change: transform;
    //         transform: rotateX("1000px");

    //         .featureMarquee-list__container {
    //             display: flex;
    //             justify-content: space-between;

    //             .featureMarquee-item {
    //                 display: flex;
    //                 justify-content: center;
    //                 align-items: center;
    //                 height: 110px;
    //                 width: 320px;
    //                 margin: 20px;
    //                 background-color: #f7f7f7;
    //             }
    //         }
    //     }
    // }
}
</style>