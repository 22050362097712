<template>
  <div class="top">
    <!-- <div class="lan">
      <div class="btn1">简体中文</div>
      <a class="btn1 btn" href="">English</a>
    </div> -->
    <div class="centerWrap">
      <h1>
        <a href="">
          <img src="../assets/logo.png" alt="希思腾科官网" title="希思腾科官网" style="margin-left: 40px; margin-top: 30px; width: 220px" />
        </a>
        希思腾科官网
      </h1>
      <ul class="nav">
        <li>
          <router-link to="/">首页</router-link>
        </li>
        <li>
          <router-link to="/product/index">产品系列</router-link>
        </li>
        <!-- <li>
              <a href="http://www.systemteq.net/news/classiccase">典型案例</a>
            </li> -->
        <!-- <li>
              <a href="http://www.systemteq.net/news/Informationexpress"
                >资讯快报</a
              >
            </li> -->
        <li>
          <router-link to="/hotel/index">解决方案</router-link>
        </li>
        <li>
          <router-link to="/cooperative">招商加盟</router-link>
        </li>
        <li>
          <router-link to="/profile/index">关于我们</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top'
}
</script>

<style lang="less" scoped>
.lan {
  position: absolute;
  right: 40px;
  top: 24px;

  // color: #000;
  // color: #e64c3d;
  // margin-left: -65px;
  // margin-top: -20px;
  // width: 150px;
  // height: 80px;
  // text-align: center;
  .btn1 {
    height: 30px;
    line-height: 16px;
    background-color: #008cba;
    border: 2px solid #0099cc;
    /* 边框样式、颜色、宽度 */
    border-radius: 70px;
    /* 给边框添加圆角 */
    text-decoration: none;
    // text-transform: uppercase; /* 字母转大写 */
    color: white;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s;
    /* 兼容 Safari */
    transition-duration: 0.4s;
    vertical-align: middle;
    // cursor: pointer;
  }

  .btn {
    background-color: white;
    color: black;
    border: 2px solid #008cba;
  }

  .btn:hover {
    background-color: #008cba;
    color: white;
  }
}

.top {
  height: 95px;
  min-width: 1152px;
  // background: #131418ec;
  background-color: #f8f7f6;
  color: #fff;
  line-height: 32px;
  .centerWrap {
    display: grid;
    grid-template-columns: 200px 940px;
    grid-template-rows: 95px;
    grid-column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px 0 0;
    h1 {
      font-size: 0;
    }
  }
  .nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
    justify-items: end;
    align-items: center;
    width: 940px;
    font-size: 20px;
    line-height: 50px;
    font-weight: 600;
    a {
      // color: rgba(255, 255, 255, 0.8);
      color: rgba(1, 1, 1, 0.8);
      &:hover {
        color: #e64c3d;
      }
    }
  }
}

</style>
