<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>希思腾科智慧酒店</h1>
                <h1 style="display: inline-block">实现酒店</h1>
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">
                    食、住、行、游、购、娱
                </h1>
                <h1 style="display: inline-block">智慧化管理</h1>
            </div>
            <article>
                希思腾科智慧酒店的智能核心控制是运用“互联网+”、物联网、云计算及人工智能等先进技术，贯通希思腾科智慧酒店云平台。实现酒店"食、住、行、游、购、娱"六大要素的智慧化管理。<br><br>
                客房控制系统是集智能灯光控制、空调控制、服务控制与管理功能于一体，具有智能化、网络化、规范化特点，将科学的管理思想与先进的管理手段相结合，帮助酒店各级管理人员和服务人员对酒店运行过程中产生的大量动态的、复杂的数据和信息进行及时准确的分析处理，从而使酒店管理真正由经验管理进入到科学管理。
            </article>
            <div><img src="../assets/zhjd.png" /></div>
        </div>
        <section class="section_cona">
            <div class="centerWrap">
                <div class="title">全场景、全流程覆盖，打造一站式沉浸体验</div>
                <div class="current">
                    <div class="cardbox">
                        <div class="card-title">酒店预订</div>
                        <div class="card-desc">通过手机APP、官网、第三方平台进行预订</div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">到店</div>
                        <div class="card-desc">导航酒店位置<br />智能停车</div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">入住</div>
                        <div class="card-desc">
                            自助入住、身份验证<br />选择房型、缴纳押金
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">住店服务</div>
                        <div class="card-desc">住宿娱乐<br />餐饮消费</div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">退房</div>
                        <div class="card-desc">
                            线上退房、退还押金<br />开具发票、行李寄送
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">离店</div>
                        <div class="card-desc">智能寻车、接车服务</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HotelIndex',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}

.section_cona {
    padding: 70px 0 20px 0;
    background: #f5f7fa;

    .title {
        margin: 0 0 20px 0;
        font-family: "MiSans-Regular";
        font-size: 48px;
        line-height: 86px;
        color: #000;
    }

    .current {
        display: grid;
        grid-template-columns: repeat(6, calc((100% - 64px) / 6));
        grid-template-rows: 60px, 1fr;
        justify-content: space-between;

        .cardbox {
            margin: 0 0 16px 0;
            padding: 28px 20px 28px 20px;
            border-radius: 20px;
            background: #ffffff;

            .card-title {
                font-size: 24px;
                color: #000000;
                line-height: 32px;
                margin-bottom: 16px;
                font-family: "MiSans-Medium";
            }

            .card-desc {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}</style>