<template>
    <div class="pagein">
        <div><img src="../assets/products01.jpg" /></div>
        <div><img src="../assets/products02.jpg" /></div>
        <div><img src="../assets/products03.jpg" /></div>
        <div><img src="../assets/products04.jpg" /></div>
        <div><img src="../assets/products06.jpg" /></div>
        <div><img src="../assets/products05.jpg" /></div>
    </div>
</template>

<script>
export default {
    name: 'ProductIndex'
}
</script>

<style lang="less" scoped>
.pagein {
    padding: 0 20px 20px 20px;

    div {
        img {
            width: 100%;
        }
    }

    article {
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}
</style>