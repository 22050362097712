<template>
    <div>
        <div class="slideshow">
            <img src="../assets/fragment/frag_products.jpg" />
        </div>
        <div class="centerWrap content">
            <ul class="pageleft">
                <li>产品系列</li>
                <li class="tabtitle">智能面板</li>
                <li><router-link to="/product/panelseries?id=Auroraseriespanel">极光系列面板</router-link></li>
                <li><router-link to="/product/panelseries?id=Electrodelessseriespanel">无极系列面板</router-link></li>
                <li><router-link to="/product/panelseries?id=Gloryseriespanel">荣耀系列面板</router-link></li>
                <li><router-link to="/product/panelseries?id=Classicseriespanel">经典系列面板</router-link></li>
                <li><router-link to="/product/panelseries?id=Futureseriespanel">未来系列面板</router-link></li>
                <li><router-link to="/product/panelseries?id=RV">畅想系列面板（房车）</router-link></li>
                <li class="tabtitle">控制系统</li>
                <li><router-link to="/product/moduleseries?id=ESseriesmodules">ES系列模块</router-link></li>
                <li><router-link to="/product/moduleseries?id=Lseriesmodules">L系列模块</router-link></li>
                <li><router-link to="/product/moduleseries?id=Hseriesmodules">H系列模块</router-link></li>
                <li class="tabtitle">公共区系统</li>
                <li><router-link to="/product/moduleseries?id=Publiczhineng">公共区智能控制系统</router-link></li>
                <li class="tabtitle">无线系统</li>
                <li><router-link to="/product/moduleseries?id=Intelligentgateway">智能网关</router-link></li>
                <li><router-link to="/product/panelseries?id=Gassensor">燃气传感器</router-link></li>
                <li><router-link to="/product/panelseries?id=Doormagneticswitchsensor">门磁开关传感器</router-link></li>
                <li><router-link to="/product/panelseries?id=Waterimmersionsensor">水浸传感器</router-link></li>
                <li><router-link to="/product/panelseries?id=PanicButton">紧急按钮</router-link></li>
                <li><router-link to="/product/panelseries?id=Intelligentinfraredtransponder">智能红外传感器</router-link></li>
                <li><router-link to="/product/panelseries?id=Humanbodymovementsensor">人体移动传感器</router-link></li>
                <li><router-link to="/product/panelseries?id=Temperatureandhumiditysensor">温湿度传感器</router-link></li>
                <li><router-link to="/product/panelseries?id=curtain">窗帘电机</router-link></li>
                <li><router-link to="/product/panelseries?id=panel">无线面板</router-link></li>
                <li><router-link to="/product/panelseries?id=airQualityDetector">空气质量检测仪</router-link></li>
            </ul>
            <router-view></router-view>
            <!-- <content class="pagein">
                <div><img src="../assets/airQualityDetector.png" /></div>
            </content> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Product'
}
</script>

<style lang="less" scoped>
.slideshow {
    vertical-align: middle;

    img {
        width: 100%;
    }
}

.content {
    display: grid;
    grid-template-columns: 240px 1fr;
    font-size: 16px;
    padding: 20px 0 0 0;

    .pageleft {
        margin: 0 0 20px 0;

        li {
            padding: 0 0 0 23px;
            line-height: 45px;
            border-bottom: 1px dotted #959595;
            height: 45px;
            text-align: left;
            font-size: 16px;
            color: #000;

            &:first-child {
                padding: 0 0 0 0;
                color: #fff;
                height: 75px;
                font-size: 30px;
                line-height: 75px;
                text-align: center;
                background: #053a75;
                font-size: 30px;
            }
        }

        .tabtitle {
            height: 55px;
            line-height: 55px;
            border-bottom: 1px solid #999999;
            font-size: 22px;
            color: #053a75;
        }
    }

}</style>