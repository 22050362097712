<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>希思腾科智慧家庭</h1>
                <h1 style="display: inline-block">多种方式实现场景联动，</h1>
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">一键</h1>
                <h1 style="display: inline-block">操控家庭设施</h1>
            </div>
            <article>
                依照空间类型，设计并推荐最适宜的智能场景，覆盖家庭多样需求。根据不同空间要求，打造灯光场景、颜色变幻、智能调光，同时可与空调、新风、电动窗帘背景音乐以及家庭影院系统联动，也可通过自动控制、语音、面板、手机等多种方式实现场景联动，一键操控家庭设施，生活便利、舒适。
            </article>
            <div><img src="../assets/horse.png" /></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HotelFamily',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}

</style>