<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>联系SYSTEMTEQ</h1>
            </div>
            <article>
                北京希思腾科智能科技有限公司<br />
                地址：北京市朝阳区广渠门外大街8号优士阁B座2304<br />
                电话：400-716-5828<br />传真：010-65749778<br />邮箱：xstk@systemteq.com.cn<br />
                邮编：100024<br />网址：www.systemteq.net
            </article>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileContactus',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    .title {
        margin: 60px 0 32px 0;
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}</style>