<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>希思腾科智慧教室</h1>
                <h1 style="display: inline-block">促进</h1>
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">
                    信息技术
                </h1>
                <h1 style="display: inline-block">与</h1>
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">
                    教育教学
                </h1>
                <h1 style="display: inline-block">融合</h1>
            </div>
            <article>
                智慧校园是指以促进信息技术与教育教学融合，提高学与教的效果为目的以物联网云计算、大数据分析等新技术为核心技术，提供一种环境全面感知智慧型、数据化、网络化、协议型一体化的教学科研、管理和生活服务，并能对教育教学、教育管理进行洞察和预测的智慧学习环境。
            </article>
            <div><img src="../assets/zhjs.jpeg" /></div>
        </div>
        <section class="section_cona">
            <div class="centerWrap">
                <div class="title">
                    提升校园安全管理，以智能化提升教学效率及管理效率
                </div>
                <div class="current" style="grid-template-columns: repeat(4, calc((100% - 64px) / 4))">
                    <div class="cardbox">
                        <div class="card-title">
                            <img src="../assets/icon/lock.png" style="margin-left: 0; margin-top: 0px; width: 60px" />
                        </div>
                        <div class="card-desc">
                            宿舍人员通过人脸识别即可开门进入，保障宿舍安全。复杂环境下也可以准确辨识，抵御照片视频攻击。寝室门口设置指纹识别门锁，准确性高。门锁开启，人进去后，自动关门。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">
                            <img src="../assets/icon/curtain.png" style="margin-left: 0; margin-top: 0px; width: 60px" />
                        </div>
                        <div class="card-desc">
                            为了满足学校教室或宿舍等场所的需要而研发的智能窗帘系统，既可以与控制系统兼容，满足整体控制功能，也能够单独应用智能面板控制或者
                            APP 远程遥控。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">
                            <img src="../assets/icon/secure.png" style="margin-left: 0; margin-top: 0px; width: 60px" />
                        </div>
                        <div class="card-desc">
                            在走廊设置烟雾传感器，当感知烟雾浓度超标时，蜂鸣报警，防止意外发生。在人员复杂的走廊和细菌滋生的卫生间设置紫外线杀菌灯。在感应到长时间无人时，自动杀菌;感应到有人经过时，自动关闭。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">
                            <img src="../assets/icon/light.png" style="margin-left: 0; margin-top: 0px; width: 60px" />
                        </div>
                        <div class="card-desc">
                            对主要区域的照明设备进行控制灯光可任意组合场景，还可以联动窗帘、空调等协同组合控制，按照不同的需求调整相应的颜色和亮度。安装智能红外设备，实现有人亮灯无人灭灯。
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HotelClassroom',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}

.section_cona {
    padding: 70px 0 20px 0;
    background: #f5f7fa;

    .title {
        margin: 0 0 20px 0;
        font-family: "MiSans-Regular";
        font-size: 48px;
        line-height: 86px;
        color: #000;
    }

    .current {
        display: grid;
        grid-template-columns: repeat(6, calc((100% - 64px) / 6));
        grid-template-rows: 60px, 1fr;
        justify-content: space-between;

        .cardbox {
            margin: 0 0 16px 0;
            padding: 28px 20px 28px 20px;
            border-radius: 20px;
            background: #ffffff;

            .card-title {
                font-size: 24px;
                color: #000000;
                line-height: 32px;
                margin-bottom: 16px;
                font-family: "MiSans-Medium";
            }

            .card-desc {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}
</style>