import VueRouter from "vue-router";
import Index from "../pages/Index";
import Product from "../pages/Product";
import ProductIndex from "../pages/ProductIndex";
import ProductPanelseries from "../pages/ProductPanelseries";
import ProductModuleseries from "../pages/ProductModuleseries";
import Hotel from "../pages/Hotel";
import HotelIndex from "../pages/HotelIndex";
import HotelFamily from "../pages/HotelFamily";
import HotelOlds from "../pages/HotelOlds";
import HotelBank from "../pages/HotelBank";
import HotelClassroom from "../pages/HotelClassroom";
import Cooperative from "../pages/Cooperative";
import Profile from "../pages/Profile";
import ProfileIndex from "../pages/ProfileIndex";
import ProfileHonor from "../pages/ProfileHonor";
import ProfileRecruit from "../pages/ProfileRecruit";
import ProfileContactus from "../pages/ProfileContactus";

export default new VueRouter({
  routes: [
    {
      path: "/",
      component: Index,
    },
    {
      path: "/product",
      component: Product,
      children: [
        { path: "index", component: ProductIndex },
        { path: "panelseries", component: ProductPanelseries },
        { path: "moduleseries", component: ProductModuleseries },
      ],
    },
    {
      path: "/hotel",
      component: Hotel,
      children: [
        { path: "index", component: HotelIndex },
        { path: "family", component: HotelFamily },
        { path: "olds", component: HotelOlds },
        { path: "bank", component: HotelBank },
        { path: "classroom", component: HotelClassroom },
      ],
    },
    {
      path: "/cooperative",
      component: Cooperative,
    },
    {
      path: "/profile",
      component: Profile,
      children: [
        { path: "index", component: ProfileIndex },
        { path: "honor", component: ProfileHonor },
        { path: "recruit", component: ProfileRecruit },
        { path: "contactus", component: ProfileContactus },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {  
    return { x: 0, y: 0 };
  },
});
