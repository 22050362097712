<template>
    <div class="pagein">
        <div v-for="n in list" :key="n.id"><img :src='"../assets/" + n + ".jpg"' /></div>
    </div>
</template>

<script>
export default {
    name: 'ProductElectrodelessseriespanel',
    data() {
        return {
            list: []
        };
    },
    methods: {
        refresh(id) {
            console.log(id);
            switch (id) {
                case 'ESseriesmodules':
                    this.list = ['ESseriesmodules08', 'ESseriesmodules07', 'ESseriesmodules06', 'ESseriesmodules05', 'ESseriesmodules04', 'ESseriesmodules03', 'ESseriesmodules02', 'ESseriesmodules01'];
                    break;
                case 'Lseriesmodules':
                    this.list = ['Lseriesmodules05', 'Lseriesmodules04', 'Lseriesmodules03', 'Lseriesmodules02', 'Lseriesmodules01'];
                    break;
                case 'Hseriesmodules':
                    this.list = ['Hseriesmodules02', 'Hseriesmodules01'];
                    break;
                case 'Publiczhineng':
                    this.list = ['publiczhineng01', 'publiczhineng02', 'publiczhineng03', 'publiczhineng04', 'publiczhineng05', 'publiczhineng06'];
                    break;
                case 'Intelligentgateway':
                    this.list = ['Intelligentgateway01', 'Intelligentgateway02'];
                    break;
            }
        }
    },
    mounted() {
        let id = this.$route.query.id;
        this.refresh(id);
    },
    watch: {
        $route: {
            handler(val, oldval) {
                console.log(val.query);//新路由信息
                console.log(oldval.query);//老路由信息
                this.refresh(val.query.id);
            }
        },
    }
}
</script>

<style lang="less" scoped>
.pagein {
    padding: 0 20px 20px 20px;

    div {
        img {
            width: 100%;
        }
    }

    article {
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}
</style>