<template>
    <div>
        <div class="centerWrap newcontent">
            <div class="title">
                <h1>希思腾科智慧养老</h1>
                <!-- <h1 style="display: inline-block"></h1> -->
                <h1 style="display: inline-block; color: rgb(10, 89, 247)">
                    实时、快捷、高效、低成本、智能化
                </h1>
                <h1 style="display: inline-block">养老服务</h1>
            </div>
            <article>
                为老人的生活提供更加便利的人性化服务，建立适老生态，改善养老产业质量。智慧养老系统在提供统一服务的同时减少运营成本，提高管理服务的效率和响应速度。在此基础上提供实时、快捷、高效、低成本的物联化、互联化、智能化的养老服务。
            </article>
            <div><img src="../assets/zhyl.jpeg" /></div>
        </div>
        <section class="section_cona">
            <div class="centerWrap">
                <div class="title">24小时全方位呵护，AI安全保障</div>
                <div class="current" style="grid-template-columns: repeat(4, calc((100% - 64px) / 4))">
                    <div class="cardbox">
                        <div class="card-title">安全</div>
                        <div class="card-desc">
                            蓝牙手环定位系统，防走失红外设备，检测老人作息。子女可以帮助老人设定时间及信息。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">报警</div>
                        <div class="card-desc">
                            SOS
                            按键报警、水浸/可燃气体传感器等设备在老人遇到危险时可第一时间将信息传至子女。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">娱乐</div>
                        <div class="card-desc">
                            使用语音机器人，用声音控制电子设备，可以选择喜欢的节目，方便快捷。
                        </div>
                    </div>
                    <div class="cardbox">
                        <div class="card-title">健康</div>
                        <div class="card-desc">
                            睡眠毯、血压仪、体脂称等设备可以将相关信息上传至后台，方便检测老人身体健康情况。
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'olds',
}
</script>

<style lang="less" scoped>
.newcontent {
    font-size: 16px;
    padding: 20px 0 0 0;
    padding: 0 0 20px 0;

    div {
        img {
            width: 100%;
        }
    }

    .title {
        margin: 60px 0 32px 0;
    }

    h1 {
        font-family: "MiSans-Regular";
        font-size: 56px;
        line-height: 86px;
        color: #000;
    }

    article {
        margin: 0 0 29px 0;
        padding: 20px 0 0 0;
        font-size: 20px;
    }
}

.section_cona {
    padding: 70px 0 20px 0;
    background: #f5f7fa;

    .title {
        margin: 0 0 20px 0;
        font-family: "MiSans-Regular";
        font-size: 48px;
        line-height: 86px;
        color: #000;
    }

    .current {
        display: grid;
        grid-template-columns: repeat(6, calc((100% - 64px) / 6));
        grid-template-rows: 60px, 1fr;
        justify-content: space-between;

        .cardbox {
            margin: 0 0 16px 0;
            padding: 28px 20px 28px 20px;
            border-radius: 20px;
            background: #ffffff;

            .card-title {
                font-size: 24px;
                color: #000000;
                line-height: 32px;
                margin-bottom: 16px;
                font-family: "MiSans-Medium";
            }

            .card-desc {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}
</style>