<template>
    <div>
        <div class="nav-a2">
            <div class="main" style="width: 800px; margin: 0 auto">
                <router-link class="item-list" active-class="active1" to="/profile/index"><img
                        src="/resource/image/develop/nav_deveco_studio.png" alt="" />
                    <p class="title">公司简介</p>
                    <div class="line"></div>
                </router-link>
                <router-link class="item-list" active-class="active2" to="/profile/honor"><img
                        src="/resource/image/develop/nav_arkui.png" alt="" />
                    <p class="title">荣誉资质</p>
                    <div class="line"></div>
                </router-link>
                <router-link class="item-list" active-class="active3" to="/profile/recruit"><img
                        src="/resource/image/develop/nav_arkcompiler.png" alt="" />
                    <p class="title">人才招聘</p>
                    <div class="line"></div>
                </router-link>
                <router-link class="item-list" active-class="active4" to="/profile/contactus"><img
                        src="/resource/image/develop/nav_deveco_testing.png" alt="" />
                    <p class="title">联系我们</p>
                    <div class="line"></div>
                </router-link>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Profile',
}
</script>

<style lang="less" scoped>
.nav-a2 {
    padding: 24px 80px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    // position: absolute;
    // top: 81px;
    // z-index: 10;
    .main {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 100px;

        .item-list {
            padding: 0 48px 0 48px;
            display: flex;
            align-items: center;

            .title {
                min-width: 60px;
                font-size: 18px;
                line-height: 24px;
                color: #000000;
                white-space: nowrap;

                &:hover {
                    color: #e64c3d;
                }
            }
        }

        .active1 {
            .title {
                color: rgb(10, 89, 247);
            }

            .line {
                display: block;
                height: 2px;
                background: #0a59f7;
                position: absolute;
                top: 98px;
                width: 72px;
                left: 111px;
            }
        }

        .active2 {
            .title {
                color: rgb(10, 89, 247);
            }
            .line {
                display: block;
                height: 2px;
                background: #0a59f7;
                position: absolute;
                top: 98px;
                width: 72px;
                left: 280px;
            }
        }

        .active3 {
            .title {
                color: rgb(10, 89, 247);
            }
            .line {
                display: block;
                height: 2px;
                background: #0a59f7;
                position: absolute;
                top: 98px;
                width: 72px;
                left: 448px;
            }
        }

        .active4 {
            .title {
                color: rgb(10, 89, 247);
            }
            .line {
                display: block;
                height: 2px;
                background: #0a59f7;
                position: absolute;
                top: 98px;
                width: 72px;
                left: 616px;
            }
        }
    }
}</style>